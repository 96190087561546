import React, { createContext, useMemo, useState } from 'react';

export const PageCategoryContext = createContext();

export const PageCategoryProvider = ({ children }) => {
    const [category, setCategory] = useState('private');

    const contextValue = useMemo(
        () => ({
            category,
            setCategory,
        }),
        [category]
    );

    return <PageCategoryContext.Provider value={contextValue}>{children}</PageCategoryContext.Provider>;
};
