// libraries
import React, { createContext, useMemo, useState } from 'react';

export const MenuStateContext = createContext();

export const MenuStateProvider = ({ children }) => {
    const [isOpen, setOpen] = useState(false);

    const contextValue = useMemo(
        () => ({
            isOpen,
            setOpen,
        }),
        [isOpen]
    );

    return <MenuStateContext.Provider value={contextValue}>{children}</MenuStateContext.Provider>;
};
