exports.components = {
  "component---src-atomic-pages-page-index-jsx": () => import("./../../../src/atomic/pages/page/index.jsx" /* webpackChunkName: "component---src-atomic-pages-page-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-asv-index-jsx": () => import("./../../../src/pages/about/asv/index.jsx" /* webpackChunkName: "component---src-pages-about-asv-index-jsx" */),
  "component---src-pages-about-bank-license-index-jsx": () => import("./../../../src/pages/about/bank-license/index.jsx" /* webpackChunkName: "component---src-pages-about-bank-license-index-jsx" */),
  "component---src-pages-about-contacts-index-jsx": () => import("./../../../src/pages/about/contacts/index.jsx" /* webpackChunkName: "component---src-pages-about-contacts-index-jsx" */),
  "component---src-pages-about-info-index-jsx": () => import("./../../../src/pages/about/info/index.jsx" /* webpackChunkName: "component---src-pages-about-info-index-jsx" */),
  "component---src-pages-about-info-info-details-index-jsx": () => import("./../../../src/pages/about/info/info-details/index.jsx" /* webpackChunkName: "component---src-pages-about-info-info-details-index-jsx" */),
  "component---src-pages-about-job-index-jsx": () => import("./../../../src/pages/about/job/index.jsx" /* webpackChunkName: "component---src-pages-about-job-index-jsx" */),
  "component---src-pages-about-results-index-jsx": () => import("./../../../src/pages/about/results/index.jsx" /* webpackChunkName: "component---src-pages-about-results-index-jsx" */),
  "component---src-pages-about-reviews-index-jsx": () => import("./../../../src/pages/about/reviews/index.jsx" /* webpackChunkName: "component---src-pages-about-reviews-index-jsx" */),
  "component---src-pages-about-support-index-jsx": () => import("./../../../src/pages/about/support/index.jsx" /* webpackChunkName: "component---src-pages-about-support-index-jsx" */),
  "component---src-pages-about-tariffs-docs-index-jsx": () => import("./../../../src/pages/about/tariffs-docs/index.jsx" /* webpackChunkName: "component---src-pages-about-tariffs-docs-index-jsx" */),
  "component---src-pages-mir-pay-conditions-index-jsx": () => import("./../../../src/pages/mir-pay/conditions/index.jsx" /* webpackChunkName: "component---src-pages-mir-pay-conditions-index-jsx" */),
  "component---src-pages-mobile-app-privacy-policy-business-index-jsx": () => import("./../../../src/pages/mobile-app-privacy-policy-business/index.jsx" /* webpackChunkName: "component---src-pages-mobile-app-privacy-policy-business-index-jsx" */),
  "component---src-pages-mobile-app-privacy-policy-index-jsx": () => import("./../../../src/pages/mobile-app-privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-mobile-app-privacy-policy-index-jsx" */),
  "component---src-pages-personal-data-policy-index-jsx": () => import("./../../../src/pages/personal-data-policy/index.jsx" /* webpackChunkName: "component---src-pages-personal-data-policy-index-jsx" */),
  "component---src-pages-personal-data-processing-index-jsx": () => import("./../../../src/pages/personal-data-processing/index.jsx" /* webpackChunkName: "component---src-pages-personal-data-processing-index-jsx" */),
  "component---src-pages-recommendation-technologies-index-jsx": () => import("./../../../src/pages/recommendation-technologies/index.jsx" /* webpackChunkName: "component---src-pages-recommendation-technologies-index-jsx" */),
  "component---src-templates-certificate-index-jsx": () => import("./../../../src/templates/certificate/index.jsx" /* webpackChunkName: "component---src-templates-certificate-index-jsx" */),
  "component---src-templates-geo-index-jsx": () => import("./../../../src/templates/geo/index.jsx" /* webpackChunkName: "component---src-templates-geo-index-jsx" */),
  "component---src-templates-job-index-jsx": () => import("./../../../src/templates/job/index.jsx" /* webpackChunkName: "component---src-templates-job-index-jsx" */),
  "component---src-templates-partner-index-jsx": () => import("./../../../src/templates/partner/index.jsx" /* webpackChunkName: "component---src-templates-partner-index-jsx" */),
  "component---src-templates-press-center-index-jsx": () => import("./../../../src/templates/press-center/index.jsx" /* webpackChunkName: "component---src-templates-press-center-index-jsx" */),
  "component---src-templates-press-center-post-index-jsx": () => import("./../../../src/templates/press-center/post/index.jsx" /* webpackChunkName: "component---src-templates-press-center-post-index-jsx" */),
  "component---src-templates-seo-index-jsx": () => import("./../../../src/templates/seo/index.jsx" /* webpackChunkName: "component---src-templates-seo-index-jsx" */),
  "component---src-templates-welcome-index-jsx": () => import("./../../../src/templates/welcome/index.jsx" /* webpackChunkName: "component---src-templates-welcome-index-jsx" */)
}

