// libraries
import React, { createContext, useMemo, useState } from 'react';
// constants
import { HEADER_DATA } from 'constants/headerData';

export const PageToggleCategoryContext = createContext();

export const PageToggleCategoryProvider = ({ children }) => {
    const defaultTab = HEADER_DATA.toggleList[0];
    const { id, width, widthMobile, position, positionMobile } = defaultTab;

    const [toggle, setToggle] = useState({
        id,
        width,
        widthMobile,
        position,
        positionMobile,
    });

    const contextValue = useMemo(
        () => ({
            toggle,
            setToggle,
        }),
        [toggle]
    );

    return <PageToggleCategoryContext.Provider value={contextValue}>{children}</PageToggleCategoryContext.Provider>;
};
