export const API = {
    PRODUCT_EXTENDED_FORM_HANDLER: '/rest/sendExtendedForm.php',
    PRODUCT_DEPOSIT_FORM_HANDLER: '/rest/sendDepositForm.php',
    PRODUCT_CREDIT_REALTY_FORM_HANDLER: '/rest/sendCreditRealtyForm.php',
    PRODUCT_CREDIT_CAR_FORM_HANDLER: '/rest/sendCreditCarForm.php',
    REVIEW_FORM_HANDLER: '/rest/sendReviewForm.php',
    SUPPORT_FORM_HANDLER: '/rest/sendSupportForm.php',
    VACANCY_FORM_HANDLER: '/rest/sendVacancyForm.php',
    BUSINESS_FORM_HANDLER: '/rest/sendBusinessForm.php',
    CREDIT_ZALOG_FORM_HANDLER: '/rest/sendCreditZalogForm.php',
    POST_TRACK_VISIT: '/sb-partner-handler/track-visits',
    HEADERS: {
        POST_JSON: {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        POST_FORM_DATA: {
            method: 'POST',
        },
    },
    RECAPTCHA_SITE_KEY: '6LfaftslAAAAALeRmy1tuYugPy_Mw-nHS74V3ShX',
};

export const COOKIE_KEYS = {
    cookieState: 'cookieBanner',
    refererUrl: 'landing_referer_url',
    visitId: 'visit_id',
    externalClientId: 'external_client_id',
};

export const QUERY_KEY = 'frmQueryString';

export const PARTNERS_QUERY_KEYS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'partner',
    'wmid',
    'clickid',
    'sub_id1',
    'sub_id2',
    'sub_id3',
    'erid',
];
