// constants
import { INNER_PATHS } from 'constants/pagePaths';
// images
import logo from 'assets/images/redesign/icons/logo.svg';
import logoBusiness from 'assets/images/redesign/icons/logo-business.svg';
import internetBank from 'assets/images/redesign/icons/internet-bank.svg';
import svoyaKreditka from 'assets/images/redesign/icons/svoya-kreditka.png';
import kreditka0 from 'assets/images/redesign/icons/kreditka-0.png';
import svoyaKarta from 'assets/images/redesign/icons/svoya-karta.png';
// import virtualnayaKarta from 'assets/images/redesign/icons/virtualnaya-karta.png';
import kreditPodZalog from 'assets/images/redesign/icons/kredit-pod-zalog.png';
import creditPodZalogAvto from 'assets/images/redesign/icons/credit-pod-zalog-avto.png';
import svoyVklad from 'assets/images/redesign/icons/svoy-vklad.png';
import svoySchet from 'assets/images/redesign/icons/svoy-schet.png';

const { cards, credits, deposits, about, home, business, app } = INNER_PATHS;

const HEADER_ABOUT = {
    id: 'about',
    title: 'О Банке',
    list: [
        {
            title: 'Наш Банк',
            list: [
                {
                    title: 'О нас',
                    link: about.main,
                },
                {
                    title: 'Пресс-центр',
                    link: about.pressCenter,
                },
                {
                    title: 'Мобильный банк',
                    link: app,
                },
                {
                    title: 'Вакансии',
                    link: about.job,
                },
                {
                    title: 'Отзывы',
                    link: about.reviews,
                },
                {
                    title: 'Контакты и реквизиты',
                    link: about.contacts,
                },
            ],
        },
        {
            title: 'Тарифы и документы',
            list: [
                {
                    title: 'Отчётность',
                    link: about.results,
                },
                {
                    title: 'Раскрываемая информация',
                    link: about.info.main,
                },
                {
                    title: 'Тарифы',
                    link: about.tariffsDocs,
                },
                {
                    title: 'Лицензия',
                    link: about.bankLicense,
                },
            ],
        },
        {
            title: 'Клиентам',
            list: [
                {
                    title: 'Служба поддержки',
                    link: about.support,
                },
            ],
        },
    ],
};

export const HEADER_DATA = {
    logo: {
        link: {
            private: home,
            business: business.main,
        },
        icon: {
            private: logo,
            business: logoBusiness,
            alt: 'Логотип Своего Банка',
        },
    },
    toggleList: [
        {
            id: 'private',
            title: 'Частным лицам',
            width: '148px',
            widthMobile: 'calc(50% - 4px)',
            position: '0px',
            positionMobile: '0px',
        },
        {
            id: 'business',
            title: 'Бизнесу',
            width: '93px',
            widthMobile: 'calc(50% - 4px)',
            position: '148px',
            positionMobile: '100%',
        },
    ],
    info: {
        internetBank: {
            private: {
                id: 'private',
                title: 'Интернет-Банк',
                subTitle: '',
                link: '/online/login',
                icon: internetBank,
            },
            business: {
                id: 'business',
                title: 'Интернет-Банк',
                subTitle: 'для бизнеса',
                link: business.internetBank,
                icon: internetBank,
            },
        },
        phone: '8-800-101-03-03',
    },
    nav: [
        {
            id: 'private',
            data: [
                {
                    id: 'cards',
                    title: 'Карты',
                    list: [
                        {
                            title: 'Своя Кредитка',
                            subTitle: 'Для удачных покупок — до&nbsp;120&nbsp;дней&nbsp;без&nbsp;%',
                            link: cards.creditCard.main,
                            icon: svoyaKreditka,
                        },
                        {
                            title: 'Кредитка 0%',
                            subTitle: 'Плати, только если пользуешься деньгами',
                            link: cards.creditCardZero,
                            icon: kreditka0,
                        },
                        {
                            title: 'Своя Карта',
                            subTitle: 'Бесплатная дебетовая карта с&nbsp;кешбэком до 8%',
                            link: cards.debitCard.main,
                            icon: svoyaKarta,
                        },
                        // {
                        //     title: 'Виртуальная карта',
                        //     subTitle: 'Карта для безопасных расчётов в&nbsp;интернете',
                        //     link: cards.virtualCard,
                        //     icon: virtualnayaKarta,
                        // },
                    ],
                },
                {
                    id: 'credit',
                    title: 'Кредиты',
                    list: [
                        {
                            title: 'Кредит под залог недвижимости',
                            subTitle: 'Большая сумма на любые цели',
                            link: credits.creditPodZalog,
                            icon: kreditPodZalog,
                        },
                        {
                            title: 'Кредит под залог авто',
                            subTitle: 'Быстрый кредит до&nbsp;5&nbsp;000&nbsp;000&nbsp;₽',
                            link: credits.avto,
                            icon: creditPodZalogAvto,
                        },
                        {
                            title: 'Своя Кредитка',
                            subTitle: 'Для удачных покупок — до&nbsp;120&nbsp;дней&nbsp;без&nbsp;%',
                            link: cards.creditCard.main,
                            icon: svoyaKreditka,
                        },
                        {
                            title: 'Кредитка 0%',
                            subTitle: 'Плати, только если пользуешься деньгами',
                            link: cards.creditCardZero,
                            icon: kreditka0,
                        },
                    ],
                },
                {
                    id: 'deposit',
                    title: 'Сбережения',
                    list: [
                        {
                            title: 'Свой Вклад',
                            subTitle: 'Высокий процент + возможность пополнения. Открытие онлайн',
                            link: deposits.svoyVklad,
                            icon: svoyVklad,
                        },
                        {
                            title: 'Свой Счёт',
                            subTitle: 'Пополнение/снятие без&nbsp;потери процентов',
                            link: deposits.svoySchyot,
                            icon: svoySchet,
                        },
                        {
                            title: 'Вклад Выгодный',
                            subTitle: 'Открытие в офисе (г. Саратов)',
                            link: deposits.vkladVygodny,
                            icon: svoyVklad,
                        },
                        {
                            title: 'Вклад Высокий процент',
                            subTitle: 'Открытие в офисе (г. Саратов)',
                            link: deposits.vysokijProcent,
                            icon: svoyVklad,
                        },
                    ],
                },
                HEADER_ABOUT,
            ],
        },
        {
            id: 'business',
            data: [
                {
                    id: 'rko',
                    title: 'РКО',
                    link: business.rko,
                    list: [],
                },
                {
                    id: 'deposit',
                    title: 'Депозиты',
                    link: business.deposits.depositBusiness,
                    list: [],
                },
                {
                    id: 'credit',
                    title: 'Кредиты',
                    link: business.credits.creditZalog,
                    list: [],
                },
                HEADER_ABOUT,
            ],
        },
    ],
};
