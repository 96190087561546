export const INNER_PATHS = {
    home: '/',
    cards: {
        creditCard: {
            main: '/cards/credit-card/',
            zakazatKreditkuDomoi: '/cards/credit-card/zakazat-kreditku-domoi/',
            snyatieNalichnyhBezProcentov: '/cards/credit-card/snyatie-nalichnyh-bez-procentov/',
            bezOtkaza: '/cards/credit-card/bez-otkaza/',
            bezProtsentov: '/cards/credit-card/bez-protsentov/',
            kreditkaSPlokhoyKreditnoyIstoriey: '/cards/credit-card/kreditka-s-plokhoy-kreditnoy-istoriey/',
            bezOfitsialnogoTrudoustroystva: '/cards/credit-card/bez-ofitsialnogo-trudoustroystva/',
            bezSpravokODokhodakhIPoruchiteley: '/cards/credit-card/bez-spravok-o-dokhodakh-i-poruchiteley/',
            dlyaRefinansirovaniya: '/cards/credit-card/dlya-refinansirovaniya/',
            kartaVDenObrashcheniya: '/cards/credit-card/karta-v-den-obrashcheniya/',
            kartaPoPasportu: '/cards/credit-card/karta-po-pasportu/',
            kartaSNizkimProtsentom: '/cards/credit-card/karta-s-nizkim-protsentom/',
            kartaSoLgotnymPeriodom: '/cards/credit-card/karta-so-lgotnym-periodom/',
            kartaSBesplatnymObsluzhivaniem: '/cards/credit-card/karta-s-besplatnym-obsluzhivaniem/',
            kartaDlyaPensionerov: '/cards/credit-card/karta-dlya-pensionerov/',
            kartaSBesplatnymPerevodom: '/cards/credit-card/karta-s-besplatnym-perevodom/',
        },
        creditCardZero: '/cards/kreditka-0-procentov/',
        debitCard: {
            main: '/cards/debit-card/',
            zakazatDebetovujuKartuSDostavkojNaDom: '/cards/debit-card/zakazat-debetovuju-kartu-s-dostavkoj-na-dom/',
            debetovayaKartaSKeshbekom: '/cards/debit-card/debetovaya-karta-s-keshbekom/',
            kartaSBesplatnymObsluzhivaniem: '/cards/debit-card/karta-s-besplatnym-obsluzhivaniem/',
            bankovskiySchetOnline: '/cards/debit-card/bankovskiy-schet-online/',
            kartaMir: '/cards/debit-card/karta-mir/',
        },
        virtualCard: '/cards/virtual-card/',
    },
    credits: {
        creditPodZalog: '/credits/credit-pod-zalog/nedvizhimosti/',
        avto: '/credits/credit-pod-zalog/avto/',
    },
    deposits: {
        svoyVklad: '/deposits/svoy-vklad/',
        svoyVkladPromo: '/deposits/svoy-vklad-promo/',
        vkladVygodny: '/deposits/vklad-vygodny/',
        vysokijProcent: '/deposits/vysokij-procent/',
        svoySchyot: '/deposits/svoy-schyot/',
    },
    about: {
        main: '/about/',
        asv: '/about/asv/',
        bankLicense: '/about/bank-license/',
        contacts: '/about/contacts/',
        results: '/about/results/',
        info: {
            main: '/about/info/',
            infoDetails: '/about/info/info-details/',
        },
        pressCenter: '/about/press-center/',
        support: '/about/support/',
        tariffsDocs: '/about/tariffs-docs/',
        reviews: '/about/reviews/',
        job: '/about/job/',
    },
    business: {
        main: '/business/',
        credits: {
            businessIpoteka: '/business/credits/business-ipoteka/',
            creditZalog: '/business/credits/credit-zalog/',
        },
        deposits: {
            depositBusiness: '/business/deposits/deposit-business/',
        },
        rko: '/business/rko/',
        internetBank: '/business/internet-bank/',
    },
    docs: {
        personalDataPolicy: '/personal-data-policy/',
        mobileAppPrivacyPolicy: '/mobile-app-privacy-policy/',
        mobileAppPrivacyPolicyBusiness: '/mobile-app-privacy-policy-business/',
        personalDataProcessing: '/personal-data-processing/',
        recommendationTechnologies: '/recommendation-technologies/',
    },
    app: '/app/',
    mirPay: '/mir-pay/',
    creditHolidays: '/credit-holidays/',
    welcome: {
        main: '/welcome/',
        ownCard: '/welcome/own-card/',
        ownCreditCard: '/welcome/own-credit-card/',
        zeroCard: '/welcome/zero-card/',
        svoyVklad: {
            main: '/welcome/svoy-vklad/',
            kakPopolnit: '/welcome/svoy-vklad/kak-popolnit',
        },
        svoyVkladPromo: {
            main: '/welcome/svoy-vklad-promo/',
            kakPopolnit: '/welcome/svoy-vklad-promo/kak-popolnit',
        },
        kreditkaZero: {
            main: '/welcome/kreditka-0/',
            mirPay: '/welcome/kreditka-0/mir-pay/',
            vnositPlatezhiProsto: '/welcome/kreditka-0/vnosit-platezhi-prosto/',
        },
        svoyaKreditka: {
            main: '/welcome/svoya-kreditka/',
            mirPay: '/welcome/svoya-kreditka/mir-pay/',
            vnositPlatezhiProsto: '/welcome/svoya-kreditka/vnosit-platezhi-prosto/',
        },
        svoyaKarta: {
            main: '/welcome/svoya-karta/',
            mirPay: '/welcome/svoya-karta/mir-pay/',
        },
    },
    page404: '/404/',
};
