// library
import React, { createContext, useEffect, useMemo, useState } from 'react';
// helpers
import { getCookie } from 'helpers/cookies';
import { INTERNAL_EXPERIMENT_COOKIE_NAME } from 'helpers/experimentSettings';

export const ExperimentContext = createContext();

export const ExperimentProvider = ({ children }) => {
    const initialState = typeof window !== 'undefined' ? getCookie(INTERNAL_EXPERIMENT_COOKIE_NAME) : '';
    const [experiments, setExperiments] = useState(initialState || '');

    const contextValue = useMemo(
        () => ({
            experiments,
            setExperiments,
        }),
        [experiments]
    );

    useEffect(() => {
        setExperiments(getCookie(INTERNAL_EXPERIMENT_COOKIE_NAME) || '');
    }, [getCookie(INTERNAL_EXPERIMENT_COOKIE_NAME)]);

    return <ExperimentContext.Provider value={contextValue}>{children}</ExperimentContext.Provider>;
};
