// libraries
import React, { createContext, useMemo, useState } from 'react';

export const NewsCategoryContext = createContext();

export const NewsCategoryProvider = ({ children }) => {
    const [selectedId, setSelectedId] = useState(0);

    const contextValue = useMemo(
        () => ({
            selectedId,
            setSelectedId,
        }),
        [selectedId]
    );

    return <NewsCategoryContext.Provider value={contextValue}>{children}</NewsCategoryContext.Provider>;
};
