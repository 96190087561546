// constants
import { PARTNERS_QUERY_KEYS } from 'constants/settings';

export const getCookie = (name) => {
    if (typeof document === 'undefined') {
        return false;
    }

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const result = parts.pop().split(';').shift();
        return decodeURIComponent(result);
    }

    return undefined;
};

export const setCookie = (name, value, hours) => {
    if (typeof document === 'undefined') {
        return false;
    }

    const minutesPerHour = 60;
    const secondsPerMinute = 60;
    const millisecondsPerSecond = 1000;
    const date = new Date();
    date.setTime(date.getTime() + hours * minutesPerHour * secondsPerMinute * millisecondsPerSecond);
    const cValue = encodeURIComponent(value) + (hours == null ? '; path=/;' : `; path=/;expires=${date.toUTCString()}`);
    document.cookie = `${name}=${cValue}`;
};

const deleteCookie = (name) => {
    return setCookie(name, null, -1);
};

export const getUrlParameters = (url) => {
    const queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    const obj = {};

    if (queryString) {
        const queryStringWithoutHash = queryString.split('#')[0];
        const arr = queryStringWithoutHash.split('&');
        for (let i = 0; i < arr.length; i++) {
            const [key, value] = arr[i].split('=');
            let paramNum;
            let paramName = key.replace(/\[\d*\]/, (v) => {
                paramNum = v.slice(1, -1);
                return '';
            });
            const paramValue = typeof value === 'undefined' ? true : value;
            paramName = paramName.toLowerCase();

            if (obj[paramName]) {
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                if (typeof paramNum === 'undefined') {
                    obj[paramName].push(paramValue);
                } else {
                    obj[paramName][paramNum] = paramValue;
                }
            } else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
};

export const setCookiesFromUrlParams = () => {
    const lifeTime = 1440;
    const urlParameters = getUrlParameters();
    const normalisedUrlParameters = {};
    const currentParametersNames = Object.keys(urlParameters);
    let shouldUpdateCookies = false;

    // NOTE: находим пересечение массивов текущих имен параметров и PARTNERS_QUERY_KEYS
    const whitelistedParamsNames = PARTNERS_QUERY_KEYS.filter((key) => currentParametersNames.includes(key));
    // NOTE: если массив пустой, куки не обновляем
    if (whitelistedParamsNames.length === 0) {
        return;
    }

    const getParamValue = (paramName, params) => {
        // NOTE: если значение это массив значений, получаем первый элемент массива
        //  Пример ?utm_source=google&utm_source=yandex
        //  В этом случае функция getUrlParameters вернет объект {utm_source: ['google', 'yandex']}
        if (Array.isArray(params[paramName])) {
            const [firstValue] = params[paramName];

            return firstValue;
        }

        return params[paramName];
    };

    // NOTE: если массив не пустой, формируем объект с нужными параметрами
    whitelistedParamsNames.forEach((paramName) => {
        normalisedUrlParameters[paramName] = getParamValue(paramName, urlParameters);
    });

    for (let i = 0; i < PARTNERS_QUERY_KEYS.length; i++) {
        const parameterName = PARTNERS_QUERY_KEYS[i];

        // NOTE: условие проверяте все случаи
        //  1. Есл параметр есть, а куки нет ("val" !== undefined)
        //  2. Если параметра нет, а кука есть (undefined !== "val")
        //  3. Если параметр есть и кука есть, но значения не равны ("val1" !== "val2")
        //  4. Если параметр есть и кука есть, и значения равны ("val" === "val")
        //  5. Если параметра нет и куки нет (undefined === undefined)
        if (normalisedUrlParameters[parameterName] !== getCookie(parameterName)) {
            shouldUpdateCookies = true;
            break;
        }
    }

    if (!shouldUpdateCookies) {
        return;
    }

    PARTNERS_QUERY_KEYS.forEach((param) => {
        deleteCookie(param);

        if (normalisedUrlParameters[param]) {
            setCookie(`${decodeURIComponent(param)}`, decodeURIComponent(normalisedUrlParameters[param]), lifeTime);
        }
    });
};
