// libraries
import React from 'react';
// components
import { RootElement } from './src/layouts/RootElement/index';
// styles
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/900.css';

export const wrapRootElement = ({ element }) => {
    return <RootElement>{element}</RootElement>;
};
