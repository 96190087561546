// constants
import { ALLOWED_URLS } from 'constants/experiments';
// helpers
import { getCookie, setCookie } from 'helpers/cookies';

export const INTERNAL_EXPERIMENT_COOKIE_NAME = 'internal-ga-ab-testing';
const experimentCookieName = 'ga-ab-testing';
const defaultLifeTime = 8760;
const maxValueCookieLength = 150;
const EXCLUDED_EXPERIMENT = 'SB_16392';

export const exp = {
    // gaw() {
    //     if (typeof window.ga === 'function') {
    //         window.ga.apply(window, arguments);
    //     }
    // },

    trackExperimentWithCustomDimension(experimentNameAndVariation) {
        if (experimentNameAndVariation.includes(EXCLUDED_EXPERIMENT)) {
            return;
        }

        let cookieValue = getCookie(INTERNAL_EXPERIMENT_COOKIE_NAME) || '';

        function validateLength() {
            let lastIndex;

            if (cookieValue.length > maxValueCookieLength) {
                lastIndex = cookieValue.lastIndexOf(' ');
                cookieValue = cookieValue.substring(0, lastIndex);
                validateLength(cookieValue);
            }
        }

        if (cookieValue.indexOf(experimentNameAndVariation) < 0) {
            cookieValue = experimentNameAndVariation + (cookieValue ? ` ${cookieValue}` : '');
            validateLength(cookieValue);
            setCookie(INTERNAL_EXPERIMENT_COOKIE_NAME, cookieValue, defaultLifeTime);
        }

        // exp.gaw(function (tracker) {
        //     // dimension1 для экспериментов по всем странам
        //     tracker.set(DEFAULT_DIMENSION_FOR_EXPERIMENT, cookieValue);
        // });
    },

    randomDistribution(experiments) {
        const VALID_DISTRIBUTION_PERCENT = 100;

        function randomInteger(min, max) {
            const correctionNumber = 0.5;
            let rand = min - correctionNumber + Math.random() * (max - min + 1);
            rand = Math.round(rand);
            return rand;
        }

        const randomVal = randomInteger(0, VALID_DISTRIBUTION_PERCENT - 1);
        let interval = 0;

        function isValidDistribution() {
            let totalDistrubution = 0;
            for (let i = 0; i < experiments.length; i++) {
                totalDistrubution += experiments[i].percent;
            }
            return totalDistrubution === VALID_DISTRIBUTION_PERCENT;
        }

        if (!isValidDistribution()) {
            // eslint-disable-next-line no-console
            console.error('distribution percent not valid');
        }

        for (let i = 0; i < experiments.length; i++) {
            if (exp.isInvolvedInExperiment(experiments[i].name)) {
                return experiments[i];
            }
        }

        for (let j = 0; j < experiments.length; j++) {
            interval += experiments[j].percent;

            if (randomVal < interval) {
                return experiments[j];
            }
        }
    },

    isInvolvedInExperiment(experimentName) {
        const cookieValue = getCookie(INTERNAL_EXPERIMENT_COOKIE_NAME) || '';

        return cookieValue.indexOf(experimentName) >= 0;
    },

    startExperiment(experiments) {
        const variation = exp.randomDistribution(experiments);

        if (typeof variation.action === 'function') {
            variation.action();
        }
        exp.trackExperimentWithCustomDimension(variation.name);
    },

    forceTrackExperimentWithCustomDimension(experimentNameAndVariation) {
        if (experimentNameAndVariation.includes(EXCLUDED_EXPERIMENT)) {
            return;
        }

        let cookieValue = getCookie(INTERNAL_EXPERIMENT_COOKIE_NAME) || '';
        const experimentName = experimentNameAndVariation.slice(0, -2);
        const variationsIsSet = cookieValue.indexOf(experimentNameAndVariation) !== -1;
        const experimentIsPlayed = cookieValue.indexOf(experimentName) !== -1;

        function validateLength() {
            let lastIndex;

            if (cookieValue.length > maxValueCookieLength) {
                lastIndex = cookieValue.lastIndexOf(' ');
                cookieValue = cookieValue.substring(0, lastIndex);
                validateLength(cookieValue);
            }
        }

        if (!variationsIsSet) {
            const position = 12;
            if (experimentIsPlayed) {
                const oldVariationPosition = cookieValue.indexOf(experimentName);
                const oldVariation = cookieValue.slice(oldVariationPosition, oldVariationPosition + position);

                cookieValue = cookieValue.replace(oldVariation, experimentNameAndVariation);
            } else {
                cookieValue = experimentNameAndVariation + (cookieValue ? ` ${cookieValue}` : '');
            }

            validateLength(cookieValue);
            setCookie(INTERNAL_EXPERIMENT_COOKIE_NAME, cookieValue, defaultLifeTime);
        }

        // exp.gaw(function (tracker) {
        //     // dimension1 для экспериментов по всем странам
        //     tracker.set(DEFAULT_DIMENSION_FOR_EXPERIMENT, cookieValue);
        // });
    },
};

export const getExperimentDataFromData = (experimentsData, experiments, experimentName) => {
    if (!experiments || !experimentName || !experimentsData) {
        return {};
    }

    // NOTE: превращает строку с экспериментами в массив и возвращает найденную по имени экспа вариацию
    const experimentVariation = experiments?.split(' ')?.find((element) => element.includes(experimentName));
    return experimentsData[experimentVariation] || {};
};

export const checkAllowedUrl = (allowedUrls) => {
    const currentUrl = window.location.pathname;
    const { matches = [], includes = [] } = allowedUrls;

    return matches.includes(currentUrl) || includes.some((partial) => currentUrl.includes(partial));
};

const checkAllowedVariation = (variation) => {
    const experimentName = variation.slice(0, -2);
    const urlsWhiteList = ALLOWED_URLS[experimentName];

    // NOTE: если для вариации нет whiteList, она разрешена
    // если whiteList есть, идет проверка, разрешен ли текущий URL для вариации
    if (!urlsWhiteList) {
        return true;
    }

    return checkAllowedUrl(urlsWhiteList);
};

export const trackAllowedExperiments = () => {
    const internalExperimentsArray = getCookie(INTERNAL_EXPERIMENT_COOKIE_NAME)?.split(' ') || [];

    // NOTE: если в external-cookie пришли неизвестные для internal-cookie вариации, переносим их в internal-cookie
    if (getCookie(experimentCookieName)) {
        const externalExperimentsArray = getCookie(experimentCookieName).split(' ');

        externalExperimentsArray.forEach((element) => {
            if (internalExperimentsArray.includes(element)) {
                return;
            }

            exp.trackExperimentWithCustomDimension(element);
        });
    }

    // NOTE: Фильтрация массива по разрешенным вариациям
    // если разрешенных вариаций нет, external-cookie удаляется
    const allowedExperiments = internalExperimentsArray.filter((variation) => checkAllowedVariation(variation));

    if (allowedExperiments.length) {
        return setCookie(experimentCookieName, allowedExperiments.join(' '));
    }
    return setCookie(experimentCookieName, '', -1);
};
