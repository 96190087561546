export const EXPERIMENTS = {
    exp16392: [
        {
            name: 'SB_16392_A',
            percent: 20,
        },
        {
            name: 'SB_16392_B',
            percent: 40,
        },
        {
            name: 'SB_16392_C',
            percent: 40,
        },
    ],
};

export const ALLOWED_URLS = {
    SB_16392: {
        matches: ['/', '/cards/credit-card/'],
        includes: ['/cards/delivery/credit-card/'],
    },
};
