// libraries
import React from 'react';
// context
import { PageToggleCategoryProvider } from 'context/PageToggleCategoryProvider';
import { MenuStateProvider } from 'context/MenuStateProvider';
import { PageCategoryProvider } from 'context/PageCategoryProvider';
import { NewsCategoryProvider } from 'context/NewsCategoryProvider';
import { ExperimentProvider } from 'context/ExperimentProvider';

export const RootElement = ({ children }) => {
    return (
        <ExperimentProvider>
            <MenuStateProvider>
                <PageToggleCategoryProvider>
                    <PageCategoryProvider>
                        <NewsCategoryProvider>{children}</NewsCategoryProvider>
                    </PageCategoryProvider>
                </PageToggleCategoryProvider>
            </MenuStateProvider>
        </ExperimentProvider>
    );
};
